/* eslint-disable no-underscore-dangle */

import axios from 'axios';
import hex from '@/utils/hex';
import storage from '@/plugins/storage';
import * as Sentry from '@sentry/vue';

import { APP_PLATFORM, APP_VERSION } from '@/config/app';
import {
  API_ADMIN_BASEURL,
  API_ADMIN_TIMEOUT,
  API_PAY_BASEURL,
  API_PAY_TIMEOUT,
} from '@/config/api';

function getSpanId() {
  return hex(16);
}

function getTraceId() {
  if (!window.__TRACE_ID__) {
    window.__TRACE_ID__ = hex(32);
  }

  return window.__TRACE_ID__;
}

function getSessionId() {
  let sessionId = storage.get('_session-id');

  if (!sessionId) {
    sessionId = hex(32);
    storage.set('_session-id', sessionId);
  }

  Sentry.getCurrentScope().setExtras({
    'X-App-Session-Id': sessionId,
  });

  return sessionId;
}

function getTraceparent() {
  return `00-${getTraceId()}-${getSpanId()}-01`;
}

function createHeaders(axiosInstance) {
  axiosInstance.interceptors.request.use((config) => {
    config.headers.Traceparent = getTraceparent();
    config.headers['X-App-Session-Id'] = getSessionId();
    config.headers['X-App-Platform'] = APP_PLATFORM;
    config.headers['X-App-Version'] = APP_VERSION;

    return config;
  });
}

const admin = axios.create({
  baseURL: API_ADMIN_BASEURL,
  timeout: API_ADMIN_TIMEOUT,
  method: 'get',
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const pay = axios.create({
  baseURL: API_PAY_BASEURL,
  timeout: API_PAY_TIMEOUT,
  method: 'get',
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

createHeaders(admin);
createHeaders(pay);

export default {
  admin,
  pay,
};
